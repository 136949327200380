<template>
  <div class="top">
    <div class="container">
      <div style="height: 10px"></div>
      <div class="teacher_info">
        <div class="info">
          <div class="div_btn" v-if="classes.length > 0">
            <div>
              <van-button
                class="class_btn"
                plain
                size="mini"
                type="primary"
                @click="toManage"
                color="#1989fa"
                >缴费管理</van-button
              >
            </div>
            <!-- <div>
              <van-button
                class="manage_btn"
                plain
                size="mini"
                type="primary"
                @click="toManage"
                color="#1989fa"
                ></van-button
              >
            </div> -->
          </div>

          <img src="../assets/default_profile.png" />
          <div class="teacher_desc">
            <span @click="toChangeTeacherName"
              >{{ teacherName }} </span>
            <span>{{ className }}&nbsp; </span>
            <span>{{ schoolName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <van-tabs v-model:active="active" type="card"> -->
    <van-tabs v-model:active="active" color="#1989fa" @click-tab="onClickTab">
      <van-tab title="待缴费" name="1"></van-tab>
      <van-tab title="缴费成功" name="2"></van-tab>
      <van-tab title="线下缴费" name="3"></van-tab>
    </van-tabs>
  </div>
  <van-empty v-if="listSize == 0" description="暂无内容" />
  <div v-if="listSize > 0" style="margin-bottom: 40px">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="10"
        @load="onLoad"
      >
        <van-cell-group
          inset
          v-for="item in list"
          :key="item.charge_id"
          style="margin-top: 5px"
          @click="toDetail(item)"
        >
          <van-cell :title="item.create_at" :value="payText" />
          <van-cell :title="item.t_charge_name" :value="'¥' + item.payment" />
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-dialog
    v-model:show="show"
    title="修改教师姓名"
    show-cancel-button
    :before-close="beforeClose"
  >
    <van-cell-group inset>
      <van-field
        v-model="teacherName"
        maxlength="8"
        label="教师姓名"
        :rules="[{ required: true }]"
        placeholder="请输入教师姓名"
      />
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import api from "../service/api";
import { getCookie, isAndroid, setCookie } from "../common/js/utils";
import { isLogin } from "../common/js/isloginUtil";
import { AESencrypt } from "../common/js/utils";
import { getStorage, setStorage } from "../common/js/storeUtil";

export default {
  setup() {
    const active = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    // const route = useRoute(); //获取到值
    const router = useRouter(); //路由传值
    const payType = ref(1);
    const teacherId = ref("");
    const teacherName = ref("");
    const schoolName = ref("");
    const class_name = ref("");
    const currentPage = ref(1);
    const payText = ref("未缴费");
    const listSize = ref(1);
    const show = ref(false);
    const childName = ref("");
    const refreshing = ref(false);
    const className = ref("");
    const classes = ref([]);

    onMounted(() => {
      /* eslint-disable */
      //setTitle("缴费列表");
      //判断有没有绑定用户,绑定则继续,没有则跳到绑定用户页面
      if (isLogin()) {
        teacherId.value = getStorage("user_id");
        teacherName.value = getStorage("userName");
        schoolName.value = getStorage("schoolName");
        className.value = getStorage("className");
        classes.value = getStorage("classes");
      } else {
        toBindUser();
      }
    });

    //去绑定用户页面
    const toBindUser = () => {
      router.push({
        path: "/",
        // query: {
        //   payment_id: item.payment_id,
        //   payType: payType.value,
        // },
      });
    };

    const onRefresh = () => {
      currentPage.value = 1;
      api
        .getTeacherPaymentList({
          teacher_id: teacherId.value,
          status: payType.value, //1待缴费，2缴费成功，3线下缴费
          page: currentPage.value,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    /**
     * 更改孩子姓名
     */
    const toChangeChildName = () => {
      show.value = true;
    };

    const beforeClose = (action) => {
      if (action === "confirm") {
        if (!checkName(childName.value)) {
          Toast("姓名只能是2-8位汉字");
          return;
        }
        updateTeacherName();
      } else {
        show.value = false;
      }
    };

    function checkName(name) {
      var reg = /^[\u4e00-\u9fa5]{2,8}$/;
      return reg.test(name);
    }

    //更改老师姓名
    // function updateTeacherName() {
    //   api
    //     .updateStudentName({
    //       student_id: child_id.value,
    //       student_name: childName.value,
    //     })
    //     .then((res) => {
    //       if (res.data.code == 1001) {
    //         show.value = false;
    //         Toast("更新成功");
    //         child_name.value = childName.value;
    //         // setStorage("childName", childName.value);
    //         let student = getStorage("student");
    //         student.name = childName.value;
    //         //更新成功后更新student和students
    //         setStorage("student", student);
    //         let students = getStorage("students");
    //         students = students.map(function (obj) {
    //           if (obj.id === student.id) {
    //             return student;
    //           }
    //           return obj;
    //         });
    //         setStorage("students", students);
    //       } else {
    //         Toast(res.data.message);
    //       }
    //     })
    //     .catch((err) => {
    //       Toast(err.message);
    //     });
    // }
    /**
     * 第一个字符用*号替换
     * @param {*} str
     */
    const safeName = (str) => {
      if (str) {
        return str.replace(str.substring(0, 1), "*");
      } else {
        return "";
      }
    };

    //跳转到详情
    const toDetail = (item) => {
      router.push({
        path: "/teacherDetail",
        query: {
          t_charge_teacher_id: item.charge_teacher_id,
          payType: payType.value,
        },
      });
    };

    const toManage = () => {
      // history.pushState({}, "", `http://${window.location.host}`);
      router.push({
        path: "/classesManage",
        query: {
          teacherId: getStorage("user_id"),
        },
      });
    };

    // const toClasses = () => {
    //   // history.pushState({}, "", `http://${window.location.host}`);
    //   router.push({
    //     path: "/classes",
    //     query: {
    //       teacherId: getStorage("user_id"),
    //     },
    //   });
    // };

    const onClickTab = ({ name }) => {
      payType.value = name;
      currentPage.value = 1;
      switch (payType.value) {
        case "1":
          payText.value = "未缴费";
          break;
        case "2":
          payText.value = "缴费成功";
          break;
        case "3":
          payText.value = "线下缴费";
          break;
        default:
          payText.value = "";
          break;
      }
      onRefresh();
    };

    const onLoad = () => {
      /**
       * code 不为空时进行请求
       */
      finished.value = false;
      api
        .getTeacherPaymentList({
          teacher_id: teacherId.value,
          status: payType.value, //1待缴费，2缴费成功，3线下缴费
          page: currentPage.value,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    return {
      list,
      onLoad,
      loading,
      finished,
      toDetail,
      active,
      toManage,
      onClickTab,
      payType,
      teacherName,
      schoolName,
      class_name,
      currentPage,
      payText,
      listSize,
      safeName,
      show,
      childName,
      toChangeChildName,
      beforeClose,
      refreshing,
      onRefresh,
      teacherId,
      // toClasses,
      className,
      classes,
    };
  },
};
</script>

<style lang="less">
.top {
  position: sticky;
  top: 0;
  z-index: 99;
  .container {
    height: 140px;
    background-color: #f2f2f2;
    .teacher_info {
      width: 94%;
      margin: 0 auto;
      height: 120px;
      border-radius: 6px;
      // background: linear-gradient(to right, #3794fc, #82BAFB);
      background: linear-gradient(to right,#1989fa, #3794fc);
      box-shadow: 0 2px 5px #1989fa;

      .info {
        display: block;
        width: 92%;
        height: 120px;
        margin: 0 auto;

        .div_btn {
          float: right;
          height: 120px;
          display: flex;
          flex-direction: column;

          .manage_btn {
            margin-top: 25px;
          }

          .class_btn {
            margin-top: 25px;
          }
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 4px;
          display: inline-block;
          overflow: hidden;
          margin-top: 30px;
        }
        .teacher_desc {
          display: inline-block;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
